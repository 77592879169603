// generic

<template>
    <component v-if="rootComponent" :is="rootComponent" :routeData="modulData">
    </component>
</template>
<script>
import GenericList from './GenericList'
import GenericView from './GenericView'
export default {
	name: 'generic',
	components: {
		GenericList, GenericView
	},
	props: {
		routeData: {
			type: Object,
			required: true,
			default: {},
		},
	},
	data: () => ({
	}),
	computed: {
		modulData() {
			if(!this.routeData) return null
			const modulData = Object.assign({}, this.routeData)
			return modulData
		},
        rootComponent() {
            if(!this.routeData) return null
            return this.routeData.rootComponent
        }
	},
	methods: {
	},
	beforeMount() {
	},
};
</script>
<style>
</style>